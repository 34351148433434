const configs = ["getContext", "sendChatMessage", "getCurExternalContact"];
const agentConfigs = ["getContext", "sendChatMessage", "getCurExternalContact"];
var time = `${new Date().getTime()}`;
import { hex_sha1 } from "./hex_sha1";

function randomString(length) {
    var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];

    return result;
}
var str = randomString(64);

// var url = location.href;
// var url =
//     window.location.href.indexOf("#") > -1 ?
//     `${window.location.href.split("#")[0]}` :
//     window.location.href;
var url = "https://h5.tinggupiao.com.cn/"

console.log(url, "xxxxxxzxx");

export async function wxconfig(jsapi_ticket, timestamp, baseUrl, arcStr, appid) {
    console.log(
        jsapi_ticket,
        arcStr,
        timestamp,
        baseUrl,
        "+++++++++++++++++++++++++++++++++++++++++++++9999"
    );
    return new Promise(function(resolve, reject) {
        var token = `jsapi_ticket=${jsapi_ticket}&noncestr=${arcStr}&timestamp=${timestamp}&url=${baseUrl}`;
        var a = "wxe07f8649f83e7cf0";
        // var b = "1000026";
        console.log("+++++++++++++++++++++++++++++++++++++++++++++8");
        wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            // appId: "ww71e3a1300fa0be96", // 必填，企业微信的corpID
            appId: "wxc87c83d8f8a89e1e", // 必填，企业微信的corpID
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: arcStr, // 必填，生成签名的随机串
            signature: jsapi_ticket, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['launchApplication', 'getInstallState'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            openTagList: ['wx-open-launch-app'],
            success: function(res) {
                // 回调
                console.log(res, "success", "config");
                console.log("+++++++++++++++++++++++++++++++++++++++++++++5");

                wx.error((res) => {
                    console.log(res, "wxConfig fail");
                    reject(res);
                    if (res.errMsg.indexOf("function not exist") > -1) {
                        console.log("版本过低请升级");
                    }
                });
            },
            fail: function(res) {
                console.log(res, "fail", "config");
                console.log("+++++++++++++++++++++++++++++++++++++++++++++7");
                if (res.errMsg.indexOf("function not exist") > -1) {
                    // alert("版本过低请升级");
                }
            },
        });
        document.addEventListener("WeixinOpenTagsError", function(t) {
            console.log("cccc");
            alert(t.detail.errMsg);
        });
        wx.ready(() => {
            console.log("+++++++++++++++++++++++++++++++++++++++++++++4");

            // 回调
            console.log("wxConfig success");
            wx.checkJsApi({
                    jsApiList: ['launchApplication', 'getInstallState'],
                    success: function(res) {
                        console.log('可用')
                        console.log('config111')
                    },
                    fail: (err) => {
                        console.log(err, '不可用')
                    }
                })
                // var token2 = `jsapi_ticket=${jsapi_ticket1}&noncestr=${str}&timestamp=${time}&url=${url}`;
                // wx.agentConfig({
                //     // corpid: "ww71e3a1300fa0be96", // 必填，企业微信的corpid，必须与当前登录的企业一致
                //     // agentid: "1000026", // 必填，企业微信的应用id （e.g. 1000247）
                //     agentid: b,
                //     corpid: a,
                //     timestamp: time, // 必填，生成签名的时间戳
                //     nonceStr: str, // 必填，生成签名的随机串
                //     signature: hex_sha1(token2), // 必填，签名，见附录-JS-SDK使用权限签名算法
                //     jsApiList: ["getContext", "sendChatMessage", "getCurExternalContact"], //必填，传入需要使用的接口名称
                //     success: function(res) {
                //         // 回调
                //         console.log(res, "success", "agentConfig");
                //         console.log(res, "+++++++++++++++++++++++++++++++++++++++++++++3");
                //         resolve(res);
                //     },
                //     fail: function(res) {
                //         console.log(res, "fail", "agentConfig");
                //         if (res.errMsg.indexOf("function not exist") > -1) {
                //             // alert("版本过低请升级");
                //         }
                //     },
                // });
        });
    });
}
export function wxready() {
    let id = null;
    wx.ready(function() {
        // @ts-ignore
        wx.invoke("getCurExternalContact", {}, function(res) {
            if (res.err_msg == "getCurExternalContact:ok") {
                //  console.log(res.userId, "userid在这里");
                id = res.userId;
            } else {
                console.log("userid错误", res);
            }
        });
    });
    return id;
}

// 获取运行环境信息
export function getUAInfo() {
    const UA = window.navigator.userAgent;
    const systemInfo = {};
    systemInfo.UA = UA;
    systemInfo.platform = UA.match(/Mozilla\/5.0 \((.*?)\)/)[1];
    if (
        UA.match(/MicroMessenger/i) == "MicroMessenger" &&
        UA.match(/wxwork/i) == "wxwork"
    ) {
        systemInfo.isWxWork = true;
        systemInfo.version = UA.match(/wxwork\/([\d.]+)/)[1];
    } else if (UA.match(/micromessenger/i) == "MicroMessenger") {
        systemInfo.isWxWork = false;
    } else {
        systemInfo.isWxWork = false;
    }
    // this.cs(`UAInfo, ${JSON.stringify(systemInfo)}`)
    // console.log(JSON.stringify(systemInfo))
    console.log(systemInfo, "systemInfosystemInfo");
    return systemInfo;
}
// getUAInfo();

export function isMobile() {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true; // 移动端
    } else {
        return false; // PC端
    }
}
//    判断后的操作
// if (isMobile()) {
//   location.href = "./mobile/index.html";
//   // 判断true跳转到这个主页
// } else {
//   location.href = "./pc/index.html";
//   // 判断false跳转到这个主页
// }